<template>
  <div class="misc-wrapper w-100">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Just a moment we're redirecting you...
        </h2>
        <p class="mb-2">Please wait a second</p>
        <b-img fluid :src="imgUrl" alt="Not authorized page" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BSpinner } from "bootstrap-vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    this.checkIfTokenExists();
  },
  methods: {
    checkIfTokenExists() {
      this.$http.get(`/api/user/token/` + this.$route.params.token)
      .then((res) => {
        // Set invite_token to localStorage
        localStorage.setItem("invite_token", this.$route.params.token);
        store.commit("formStoreModule/setUserInvitedToken", this.$route.params.token);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Complete any questionnaire",
            text: "Then Sign Up to view your results and get more support!",
            icon: "CheckSquareIcon",
            variant: "info",
          },
        },{
          timeout: 10000,
        });
        // Redirect to form page
        this.$router.push({ path: 'form' })
      }).catch((error) => {
        console.log(error)
        
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Invalid invitation link! Please try again!",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        // Redirect to login page
        this.$router.push({ path: 'login' })
      })
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.custom-control-label:hover {
  color: var(--mhc-gray)!important;
}
</style>
